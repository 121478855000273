import request from '@/utils/request';
export var defaultUsersRoleData = {
  id: 0,
  enabled: true,
  allowCod: false,
  allowBazarDhara: false,
  name: '',
  percentage: 100
};
export var getUsersRoles = function getUsersRoles(params) {
  return request({
    url: '/users-roles',
    method: 'get',
    params: params
  });
};
export var getRoleById = function getRoleById(id) {
  return request({
    url: "/users-roles/".concat(id),
    method: 'get'
  });
};
export var updateRole = function updateRole(id, data) {
  return request({
    url: "/users-roles/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteRole = function deleteRole(id) {
  return request({
    url: "/users-roles/".concat(id),
    method: 'delete'
  });
};
export var createRole = function createRole(data) {
  return request({
    url: '/users-roles/',
    method: 'post',
    data: data
  });
};
export var getRoutes = function getRoutes(params) {
  return request({
    url: '/routes',
    method: 'get',
    params: params
  });
};