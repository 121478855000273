var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "sub-title",
      attrs: { effect: "dark", content: _vm.content, placement: _vm.placement }
    },
    [_c("el-button", { attrs: { type: "text", icon: "el-icon-question" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }